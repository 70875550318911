<template>
    <b-row>
        <b-col>
            <div>
                <!-- Delete Modal -->
                <b-modal
                    id="remove_modal"
                    title="Confirm"
                    size="sm"
                    no-close-on-backdrop
                    no-close-on-esc
                    hide-footer
                >
                    <div class="text-center">
                        <h1>Are you sure?</h1>
                        <p>
                            You are about to remove the team member:<br />
                            <strong>"{{ remove_member.name }}"</strong>
                        </p>
                        <base-button
                            type="danger"
                            :pill="true"
                            class="my-4 btn-success"
                            @click="remove_team_member(remove_member)"
                            >Remove
                        </base-button>
                        <base-button
                            type="primary"
                            :pill="true"
                            class="my-4 btn-success"
                            @click="$bvModal.hide('remove_modal')"
                            >Cancel
                        </base-button>
                    </div>
                </b-modal>

                <!-- Leave Modal -->
                <b-modal
                    id="leave_modal"
                    title="Confirm"
                    size="sm"
                    no-close-on-backdrop
                    no-close-on-esc
                    hide-footer
                >
                    <div class="text-center">
                        <h1>Are you sure?</h1>
                        <p>You are about to leave the team.</p>
                        <base-button
                            type="danger"
                            :pill="true"
                            class="my-4 btn-success"
                            @click="leave_team()"
                            >Leave
                        </base-button>
                        <base-button
                            type="primary"
                            :pill="true"
                            class="my-4 btn-success"
                            @click="$bvModal.hide('leave_modal')"
                            >Cancel
                        </base-button>
                    </div>
                </b-modal>

                <!-- Edit Member Modal -->
                <b-modal
                    id="ru_member"
                    title="Edit Member"
                    size="sm"
                    no-close-on-backdrop
                    no-close-on-esc
                    hide-footer
                >
                    <b-row>
                        <b-col> Team Admin </b-col>
                        <b-col>
                            <base-switch
                                v-model="update_member.team_admin"
                            ></base-switch>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col>
                            <div class="text-left">
                                <base-button
                                    type="danger"
                                    :pill="true"
                                    class="my-4 btn-success"
                                    @click="ru_team_member(update_member)"
                                    >Save
                                </base-button>
                                <base-button
                                    type="primary"
                                    :pill="true"
                                    class="my-4 btn-success"
                                    @click="
                                        $bvModal.hide('ru_member');
                                        $apollo.queries.get_hackathon_team_members.refetch();
                                    "
                                    >Cancel
                                </base-button>
                            </div>
                        </b-col>
                    </b-row>
                </b-modal>
                <!-- Body -->
                <b-card>
                    <b-row>
                        <b-col sm="auto">
                            <h1 class="mb-0 text-nowrap">
                                Team
                                <base-button
                                    v-if="
                                        team.is_member &&
                                        $route.name !== 'HackathonTeam'
                                    "
                                    type="primary"
                                    pill
                                    size="sm"
                                    @click="
                                        $router.push({
                                            name: 'HackathonTeam',
                                            params: {
                                                hackathon_id: hackathon.id,
                                                team_id: team_id,
                                            },
                                        })
                                    "
                                    >Go to team</base-button
                                >
                            </h1>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <el-table
                                class="table-responsive align-items-center table-flush no-lines"
                                header-row-class-name="thead-light"
                                row-class-name="table-rows"
                                :cell-class-name="cell_style"
                                :data="team_members"
                            >
                                <el-table-column
                                    label="Name"
                                    min-width="200px"
                                    prop="name"
                                    sortable
                                >
                                    <template v-slot="{ row }">
                                        {{ row.name }}
                                    </template>
                                </el-table-column>
                                <el-table-column label="" max-width="50px">
                                    <template v-slot="{ row }">
                                        <a
                                            v-if="row.linkedin_url"
                                            :href="`${row.linkedin_url}`"
                                            target="_blank"
                                            ><i class="fab fa-linkedin-in"></i
                                        ></a>
                                    </template>
                                </el-table-column>
                                <!-- <el-table-column
                                    label="Role"
                                    v-if="!view_only"
                                >
                                    <template v-slot="{ row }">
                                        {{ row.role }}
                                    </template>
                                </el-table-column> -->

                                <el-table-column label="" class="pl-0">
                                    <template v-slot="{ row }">
                                        <b-badge
                                            pill
                                            variant="primary"
                                            v-if="row.team_admin"
                                            >Team Admin</b-badge
                                        >
                                    </template>
                                </el-table-column>

                                <el-table-column
                                    align="right"
                                    v-if="!view_only"
                                >
                                    <div
                                        slot-scope="{ row }"
                                        class="table-actions"
                                    >
                                        <el-tooltip
                                            content="Edit"
                                            placement="top"
                                        >
                                            <a
                                                v-if="
                                                    team.is_admin && !row.is_me
                                                "
                                                href="#!"
                                                @click.prevent="
                                                    show_ru_modal(row)
                                                "
                                                class="table-action"
                                                data-toggle="tooltip"
                                                data-original-title="Edit"
                                            >
                                                <i class="fas fa-edit"></i>
                                            </a>
                                        </el-tooltip>
                                        <el-tooltip
                                            content="Remove Member"
                                            placement="top"
                                        >
                                            <a
                                                v-if="
                                                    team.is_admin && !row.is_me
                                                "
                                                href="#!"
                                                @click.prevent="
                                                    show_remove_modal(row)
                                                "
                                                class="table-action table-action-delete"
                                                data-toggle="tooltip"
                                                data-original-title="Remove member"
                                            >
                                                <i class="fas fa-trash"></i>
                                            </a>
                                        </el-tooltip>

                                        <a
                                            v-if="row.is_me"
                                            class="table-action"
                                            data-toggle="tooltip"
                                            data-original-title="Leave Team"
                                        >
                                            <base-button
                                                type="danger"
                                                size="sm"
                                                :pill="true"
                                                @click="show_leave_modal(row)"
                                                >Leave Team<i
                                                    class="fas fa-sign-out-alt"
                                                ></i>
                                            </base-button>
                                        </a>
                                    </div>
                                </el-table-column>
                            </el-table>
                        </b-col>
                    </b-row>
                </b-card>
            </div>
        </b-col>
    </b-row>
</template>

<script>
// Components
import {
    Table,
    TableColumn,
    DropdownMenu,
    DropdownItem,
    Dropdown,
    Tooltip,
} from "element-ui";

// Queries
import { GET_HACKATHON_TEAM_MEMBERS } from "@/graphql/queries";

// Mutations
import {
    REMOVE_HACKATHON_TEAM_MEMBER,
    HACKATHON_TEAM_MEMBER_LEAVE,
    UPDATE_HACKATHON_TEAM_MEMBER,
} from "@/graphql/mutations";

export default {
    name: "HackathonTeamMembers",
    components: {
        [Tooltip.name]: Tooltip,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Dropdown.name]: Dropdown,
        [DropdownItem.name]: DropdownItem,
        [DropdownMenu.name]: DropdownMenu,
    },
    emits: [],
    props: {
        team_id: {
            type: String,
            description: "The id of the team.",
            default: null,
        },
        view_only: {
            type: Boolean,
            description: "Makes the team view only",
            default: false,
        },
    },
    apollo: {
        get_hackathon_team_members: {
            query: GET_HACKATHON_TEAM_MEMBERS,
            result(res) {
                this.team_members = [];
                let f_data = graph_utils.flatten_objects_recursive(
                    graph_utils.apollo_to_obj_recursive(
                        res.data.allHackathonTeamTeamMember
                    )
                );
                f_data.forEach((el) => {
                    this.team_members.push({
                        id: el.id,
                        //role: el.role,
                        name: `${el.profile__user__firstName} ${el.profile__user__lastName}`,
                        linkedin_url: el.profile__linkedinUrl,
                        team_admin: el.role == "TEAM_ADMIN",
                        is_me: el.isMe,
                    });
                });

                this.team.is_admin = res.data.hackathonTeam.isAdmin;
                this.team.is_member = res.data.hackathonTeam.isMember;
                this.hackathon.id = res.data.hackathonTeam.hackathon.id;
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            variables() {
                return {
                    team_id: this.team_id,
                };
            },
            update(data) {
                this.apollo_data.get_hackathon_team_members = data;
            },
            skip: true,
        },
    },
    data() {
        return {
            apollo_data: {
                get_hackathon_team_members: null,
            },
            remove_member: {
                name: null,
            },
            leave_member: {},
            update_member: {
                name: null,
                team_admin: false,
            },
            team: {
                is_admin: false,
                is_member: false,
            },
            hackathon: {
                id: "",
            },

            team_members: [
                //{
                //    role: "Hacker",
                //    name: "Joe",
                //    team_admin: true,
                //    id: "ID",
                //},
            ],
        };
    },
    methods: {
        // Apollo
        manage_get_hackathon_team_members() {
            if (this.team_id != null) {
                graph_utils.enable_query(
                    this.$apollo.queries.get_hackathon_team_members
                );
            } else {
                graph_utils.disable_query(
                    this.$apollo.queries.get_hackathon_team_members
                );
            }
        },

        // Modal
        show_remove_modal(team_member) {
            this.remove_member = team_member;
            this.$bvModal.show("remove_modal");
        },

        remove_team_member() {
            this.$apollo
                .mutate({
                    mutation: REMOVE_HACKATHON_TEAM_MEMBER,
                    variables: {
                        member_id: this.remove_member.id,
                    },
                })
                .then((res) => {
                    this.$bvModal.hide("remove_modal");
                    // Refetching the program data
                    this.$apollo.queries.get_hackathon_team_members.refetch();
                })
                .catch((res) => {
                    // TODO: Create notification that something went wrong.
                });
        },

        show_leave_modal(team_member) {
            this.leave_member = team_member;
            this.$bvModal.show("leave_modal");
        },

        leave_team() {
            this.$apollo
                .mutate({
                    mutation: HACKATHON_TEAM_MEMBER_LEAVE,
                    variables: {
                        member_id: this.leave_member.id,
                    },
                })
                .then((res) => {
                    this.$bvModal.hide("leave_modal");
                    // Refetching the program data
                    global_event_emitter.$emit(
                        "hackathon_sidebar_reload_required"
                    );
                    setTimeout(() => {
                        this.$router.push({
                            path: `/hackathon/${encodeURIComponent(
                                this.hackathon.id
                            )}/detail`,
                        });
                    }, 1000);
                })
                .catch((res) => {
                    // TODO: Create notification that something went wrong.
                });
        },

        show_ru_modal(team_member) {
            this.update_member = team_member;
            this.$bvModal.show("ru_member");
        },

        ru_team_member(team_member) {
            this.$apollo
                .mutate({
                    mutation: UPDATE_HACKATHON_TEAM_MEMBER,
                    variables: {
                        member_id: this.update_member.id,
                        role: team_member.team_admin ? "TEAM_ADMIN" : "MEMBER",
                    },
                })
                .then((res) => {
                    this.$bvModal.hide("ru_member");
                    // Refetching the program data
                    this.$apollo.queries.get_hackathon_team_members.refetch();
                })
                .catch((res) => {
                    // TODO: Create notification that something went wrong.
                });
        },

        cell_style(data) {
            // Works with EL Table to generate cell style. https://element-plus.org/en-US/component/table.html#table-attributes

            if (data.columnIndex == 0) {
                return "right-unpadded";
            }
            if (data.columnIndex == 3) {
                return "left-unpadded";
            }
            return "horizontally-unpadded";
        },
    },

    created() {
        global_event_emitter.$on("hackathon_team_updated", () => {
            setTimeout(() => {
                this.$apollo.queries.get_hackathon_team_members.refetch();
            }, 500);
        });
    },
    beforeDestroy() {
        global_event_emitter.$off("hackathon_team_updated");
    },
    mounted() {
        this.manage_get_hackathon_team_members();
    },
    watch: {
        team_id(new_id) {
            this.manage_get_hackathon_team_members();
        },
    },
};
</script>

<style>
.horizontally-unpadded {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.right-unpadded {
    padding-right: 0 !important;
}
.left-unpadded {
    padding-left: 0 !important;
}
</style>
